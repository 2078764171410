<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i  v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_7'])"
        @click="openSectionModal(sectionSelectedData)" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="d-flex justify-content-end m-3">
      <h3 class="font-weight-bold my-0">{{ sectionSelectedData.category ? sectionSelectedData.category.name : '' }}</h3>
    </div>
    <div class="row pt-4 pb-2 container ml-2 d-flex justify-content-end">
      <div dir="rtl" id="scrolling-wrapper" class="scrolling-wrapper" :v-if="sectionSelectedData.news">
        <div class="image-card text-right" v-for="news of sectionSelectedData.news">
          <img class="card-img-top" :src="getUrl()+news.image" alt="no image for this news">
          <div dir="rtl" class=" title font-weight-bold">{{ news.title}}</div>
        </div>
      </div>
    </div>
    <seventh-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_7'])"
      :visible="sectionModalVisibility"
      :selectedData="modalSelectedData"
      :categoriesData="categoriesData"
      :classificationData="classificationData"
      @close="closeSectionModal"
      @saveData="saveData"
    >

    </seventh-section-modal>
  </fieldset>
</template>

<script>
import SeventhSectionModal from '@/pages/visualEditors/homePage/seventhSection/SeventhSectionModal';

export default {
  name: 'seventh-section',
  components: {
    SeventhSectionModal
  },
  watch: {
    sectionData: {
      handler: function (newValue) {
        if (newValue) {
          this.sectionSelectedData = newValue;
        }
      },
      deep: true
    }
  },
  props: {
    sectionData: {
      type: Object,
    },
    lang:{
      type:String,
      default:'ar'
    },
    classificationData: {
      type: Array
    },
    categoriesData: {
      type: Array
    },
  },
  mounted() {
    this.modalSelectionList = this.selectedNews;
  },
  data() {

    return {
      modalSelectionList: Object,
      sectionModalVisibility: false,
      modalSelectedData: {},
      sectionSelectedData: {type:'',category: null,classification: null, news: []},
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSectionModal(sectionData) {
      this.modalSelectedData= {...sectionData};
      this.sectionModalVisibility = true;
    },
    closeSectionModal() {
      this.sectionModalVisibility = false;
    },
    saveData(value) {
      value.lang = this.lang;
      this.$emit('saveData', value);
    }
  }

}
</script>
<style>

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  margin: 5px 5px 1.5em 5px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}
legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.visual-editor-btn {
  cursor: pointer;
}
.image-card img{
  width: 100%;
  height: 100%;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.image-card .title{
  overflow: hidden;
}

</style>
