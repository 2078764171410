var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"center":"","visible":_vm.visible},on:{"close":function($event){return _vm.closeModal()}}},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"title","rules":"required","name":"URL Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Title *","name":"title","fou":""},on:{"keyup":_vm.generateURL},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"sub_title","rules":"required","name":"Sub Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Sub Title *","name":"sub_title","fou":""},on:{"keyup":_vm.generateURL},model:{value:(_vm.sub_title),callback:function ($$v) {_vm.sub_title=$$v},expression:"sub_title"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"url","rules":"required","name":"URL Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"URL *","name":"url","fou":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})]}}])}),_c('paginated-select',{key:_vm.paginatedSelectKey,attrs:{"labelTitle":'Choose News',"isMultiple":true,"apiUrl":'ar/news/paginatedNews',"lastData":_vm.draggableNews},on:{"select-changed":_vm.draggableNewsChanged}})],1),_c('draggable',{staticClass:"container",attrs:{"group":"news"},on:{"start":function($event){_vm.drag=true},"end":_vm.moveEventfunction},model:{value:(_vm.draggableNews),callback:function ($$v) {_vm.draggableNews=$$v},expression:"draggableNews"}},_vm._l((_vm.draggableNews),function(item,x){return _c('div',{key:'Item_'+x},[_c('card',{staticClass:"mt-2 mb-0 text-right"},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"badge badge-success mx-2"},[_vm._v(_vm._s(x+1))])])],1)}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.confirmModal()}}},[_vm._v("Confirm")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }