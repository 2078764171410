<template>
  <fieldset class="scheduler-border">
    <legend class="scheduler-border">
      <i v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_3'])"
        @click="openSectionModal()" class="fas fa-pen visual-editor-btn"></i>
    </legend>
    <div class="row pt-4 pb-2 container ml-2">
      <div dir="rtl" id="scrolling-wrapper" class="scrolling-wrapper w-100">
        <div class="text-center mb-2">
          <h3 class="font-weight-bold my-0">{{ selectedNews.title }}</h3>
          <a :href="selectedNews.url">{{ selectedNews.sub_title }}</a>
        </div>
        <div class="image-card text-right" v-for="news of selectedNews.news">
          <img class="card-img-top" :src="getUrl()+news.image" alt="no image for this news">
          <p dir="rtl" class="card-text news-title">{{ news.title}}</p>
        </div>
      </div>
    </div>
    <third-section-modal v-if="$store.getters['auth/haveOneOfPermissions'](['ar_home_page_section_3'])"
      :visible="sectionModalVisibility"
      :selectedNews="modalSelectionList"
      @close="closeSectionModal"
      @selectedNews="passSelectedNews"
    >

    </third-section-modal>
  </fieldset>
</template>

<script>
import ThirdSectionModal from '@/pages/visualEditors/homePage/thirdSection/ThirdSectionModal.vue';

export default {
  name: 'third-section',
  components: {
    ThirdSectionModal
  },
  watch: {
    selectedNews: {
      handler: function (newValue) {
        // this.selectedNews.news.map((element, index) => {
        //   this.modalSelectionList.push({id: element.id, title: element.title});
        // })

          this.modalSelectionList = this.selectedNews;
      },
      deep: true
    }
  },
  props: {
    selectedNews: {
      type: Object,
    },
  },
  mounted() {

    // this.selectedNews.map((element, index) => {
    //   this.modalSelectionList.push({id: element.id, title: element.title});
    // })
      this.modalSelectionList = this.selectedNews;
  },
  data() {

    return {
      modalSelectionList: Object,
      sectionModalVisibility: false,
    }
  },

  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    openSectionModal() {
      this.sectionModalVisibility = true;
    },
    closeSectionModal() {
      this.sectionModalVisibility = false;
    },
    passSelectedNews(value) {
        if (value.news){
            value.news.map((element, index) => {
                element.sort_order =  index+1;
            });
        }
        let data = {
            'key': 'ar_home_page_section_3',
            'value': value,
            'lang':this.lang,
        }
        this.$emit('saveData', data);
    }
  }

}
</script>
<style>

fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  margin: 5px 5px 1.5em 5px !important;
  padding: 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.visual-editor-btn {
  cursor: pointer;
}

.section1-part1 {
  height: 407px;
}

.section1-part2 {
  height: 120px;
}

.section1-part1-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}

.section1-part2-larg-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.image {
  width: 100%;
  height: 100%;
}

.text {
  font-size: 12px;
  color: white;
  text-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.5);
  margin: 5px;
  position: absolute;
}

  .image-card{
    position: relative;
    width: 18rem;
    height: 175px;
    margin-right: 10px;
    margin-bottom: 20px;
    display: inline-block;
}
  .image-card img{
    width: 100%;
    height: 100%;
  }
  .image-card p{
    position: absolute;
    right: 0;
    bottom: 0px;
    max-width: 200px;
    background-color: #ffffff;
    color: #000000;
    height: 62px;
    white-space: break-spaces;

  }
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
