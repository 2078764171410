<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">

    <div class="col-md-12">
      <fg-select
        name="column_type"
        size="large"
        filterable
        clearable
        placeholder="Type"
        :input-classes="'select-default'"
        :label="'Column Type'"
        :list="columnTypeList"
        :listItemLabel="'name'"
        :listItemValue="'name'"
        v-model="columnType">
      </fg-select>
      <div v-if="columnType == 'classification'">
        <fg-select
          name="classification_id"
          size="large"
          filterable
          clearable
          placeholder="Classification"
          :input-classes="'select-default'"
          :label="'Classification'"
          :list="classificationList"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          @change="classificationChanged()"
          v-model="classification_id">
        </fg-select>
        <paginated-select
          :labelTitle="'Choose News'"
          :isMultiple="true"
          @select-changed="draggableClassificationNewsChanged"
          :filterData="{key: 'classification_id', value: this.classification_id}"
          :apiUrl="'ar/news/paginatedNews'"
          :lastData="draggableNews"
          :selectLimiter="3"
          :key="paginatedSelectKey"
        >
        </paginated-select>
      </div>
      <div v-if="columnType == 'category'">
        <fg-select
          name="category_id"
          size="large"
          filterable
          clearable
          placeholder="Categories"
          :input-classes="'select-default'"
          :label="'Categories'"
          :list="categoryList"
          :listItemLabel="'name'"
          :listItemValue="'id'"
          @change="categoryChanged()"
          v-model="category_id">
        </fg-select>
        <paginated-select
          :labelTitle="'Choose News'"
          @select-changed="draggableCategoryNewsChanged"
          :filterData="{key: 'category_id', value: this.category_id}"
          :apiUrl="'ar/news/paginatedNews'"
          :lastData="draggableCategoryNews"
          :selectLimiter="3"
          :key="paginatedSelectKey"
        >
        </paginated-select>
      </div>
    </div>
    <draggable v-if="columnType == 'classification'" class="container" v-model="draggableNews" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableNews" :key="'Item_'+x">
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>

      </div>
    </draggable>
    <draggable v-if="columnType == 'category'" class="container" v-model="draggableCategoryNews" group="news" @start="drag=true" @end="moveEventFunction">
      <div v-for="(item, x) in draggableCategoryNews" :key="'Item_'+x">
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>
      </div>
    </draggable>


    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog, Option, Select} from 'element-ui'
import {SelectPage} from 'v-selectpage'
import "vue-select/dist/vue-select.css";
import draggable from 'vuedraggable';
import FgSelect from "@/components/Inputs/formGroupSelect";
import PaginatedSelect from "../../../../components/paginatedSelect";


export default {
  name: 'category-section-modal',
  components: {
    PaginatedSelect,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
    FgSelect,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    selectedData: {
      type: Object,
    },
    classificationData: {
      type: Array,
    },
    categoriesData: {
      type: Array,
    },
    column:{
      type:String,
    },
    section:{
      type:String,
    },
  },
  data() {
    return {
      newsList: [],
      selectKey: 0,
      categoryList: [],
      category_id: null,
      classificationList: [],
      classification_id: null,
      draggableNews: [],
      draggableCategoryNews: [],
      columnType: '',
      columnTypeList:[{name :'category'},{name:'classification'}],
      paginatedSelectKey: 0,
    }
  },
  watch: {
    classificationData: {
      handler: function (newValue) {
        this.classificationList = newValue;
        this.paginatedSelectKey++;
      },
      deep: true
    },
    categoriesData: {
      handler: function (newValue) {
        this.categoryList = newValue;
        this.paginatedSelectKey++;

      },
      deep: true
    },
    selectedData: {
      handler: function (newValue) {
        this.setModalData();
        this.paginatedSelectKey++;
      },
      deep: true
    },
  },
  mounted() {
    this.draggableNews = this.selectedNews;
    this.draggableCategoryNews = this.selectedNews;
  },
  methods: {
    draggableCategoryNewsChanged(selectedNews){
      this.draggableCategoryNews = selectedNews
    },
    draggableClassificationNewsChanged(selectedNews){
      this.draggableNews = selectedNews
    },
    classificationChanged(){
      this.paginatedSelectKey++;
      this.draggableNews = [];
      this.draggableCategoryNews = [];
    },
    categoryChanged(){
      this.paginatedSelectKey++;
      this.draggableNews = [];
      this.draggableCategoryNews = [];
    },
    closeModal() {
      this.$emit('close');
    },
    setModalData() {
      this.classification_id = null;
      this.category_id = null;
      this.draggableNews = [];
      this.draggableCategoryNews = [];

      if (this.selectedData) {
        if (this.selectedData.news) {
          if(this.selectedData.type == 'category'){
            this.selectedData.news.map((element, index) => {
              this.draggableCategoryNews.push({id: element.id, title: element.title});
            })
          }else{
            this.selectedData.news.map((element, index) => {
              this.draggableNews.push({id: element.id, title: element.title});
            })
          }
        }
        this.columnType = this.selectedData.type;
        if (this.selectedData.classification) {
          this.classification_id = this.selectedData.classification.id;
        }
        if (this.selectedData.category) {
          this.category_id = this.selectedData.category.id;
        }
      }
    },
    confirmModal() {
      let selectedData = {
        'type':this.columnType,
        'classification_id': (this.columnType == 'classification')? this.classification_id : null,
        'category_id': (this.columnType == 'category')? this.category_id : null,
        'news': []
      }
      if(this.columnType == 'category'){
        this.draggableCategoryNews.map((element, index) => {
          let order = element.sort_order = index + 1;
          selectedData.news.push({...element, sort_order: order});
        });
      }else{
        this.draggableNews.map((element, index) => {
          let order = element.sort_order = index + 1;
          selectedData.news.push({...element, sort_order: order});
        });
      }
      let result = {
        'news':this.draggableNews,
        'category':{id: this.category_id}
      }
      let data = {
        'key': 'ar_home_page_category_section_'+this.section+'_'+this.column,
        'value': selectedData,
      }
      this.$emit('saveData', data);
      this.closeModal();
    },
    newsPaginated() {
      if (!this.classification_id && !this.category_id) {
        return;
      }
      let filterData = (this.columnType == 'category')? {key: 'category_id', value: this.category_id} : {key: 'classification_id', value: this.classification_id};
      let paginatorData = {
        offset: this.offset,
        limit: this.limit,
        search: this.search,
        filters: [filterData]
      };
      this.axios.post("ar/news/paginatedNews", paginatorData).then((response) => {
        this.newsList = response.data.news;
        // this.newsCount = response.data.newsCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    moveEventFunction() {
      // this.news.map((element, index) => { element.sort_order =  index})
    },
  }

}
</script>
<style>

</style>
