<template>
<div>
  <div  class="card border-0">
    <div v-if="news && news[0]">
      <img :src="getUrl()+news[0].image" alt="no Image Found" style="width:100%; max-height: 300px">
      <div class="d-flex align-items-end flex-column" >
        <div class="p-2 font-weight-bold text-right">{{ news[0].title }}</div>
        <div class="p-2 text-right">{{ news[0].excerpt }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3" v-if="news && news[1]">
      <div>
        <div class="px-2 py-1 text-right font-weight-bold">{{ news[1].title }}</div>
        <div class="px-2 py-1 text-right">{{ news[1].excerpt }}</div>
      </div>
      <img class="mx-3" :src="getUrl()+news[1].image" alt="no Image Found" style="width:40%; height:40%">

    </div>

    <div class="d-flex justify-content-end mt-3" v-if="news && news[2]">
      <div>
        <div class="px-2 py-1 text-right font-weight-bold">{{ news[2].title }}</div>
        <div class="px-2 py-1 text-right">{{ news[2].excerpt }}</div>
      </div>
      <img class="mx-3" :src="getUrl()+news[2].image" alt="no Image Found" style="width:40%; height:40%">

    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'category-section-news',
  components: {
  },
  props:['news'],
  data() {

    return {
    }
  },
  mounted() {
  },
  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
  },
}
</script>
<style>
</style>

