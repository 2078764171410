<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <div class="col-md-12">
      <ValidationProvider
              vid="title"
              rules="required"
              name="URL Title"
              v-slot="{ passed, failed,errors }">
        <fg-input type="text"
                  :error="failed ? errors[0]: null"
                  label="Title *"
                  name="title"
                  fou
                  @keyup="generateURL"
                  v-model="title">
        </fg-input>
      </ValidationProvider>
      <ValidationProvider
        vid="sub_title"
        rules="required"
        name="Sub Title"
        v-slot="{ passed, failed,errors }">
        <fg-input type="text"
                  :error="failed ? errors[0]: null"
                  label="Sub Title *"
                  name="sub_title"
                  fou
                  @keyup="generateURL"
                  v-model="sub_title">
        </fg-input>
      </ValidationProvider>
      <ValidationProvider
              vid="url"
              rules="required"
              name="URL Title"
              v-slot="{ passed, failed,errors }">
        <fg-input type="text"
                  :error="failed ? errors[0]: null"
                  label="URL *"
                  name="url"
                  fou
                  v-model="url">
        </fg-input>
      </ValidationProvider>
      <paginated-select
        :labelTitle="'Choose News'"
        :isMultiple="true"
        @select-changed="draggableNewsChanged"
        :apiUrl="'ar/news/paginatedNews'"
        :lastData="draggableNews"
        :key="paginatedSelectKey"
      >
      </paginated-select>
    </div>
    <draggable class="container"  v-model="draggableNews" group="news" @start="drag=true" @end="moveEventfunction">
      <div v-for="(item, x) in draggableNews" :key="'Item_'+x" >
        <card class="mt-2 mb-0 text-right">
          <span>{{ item.title }}</span>
          <span class="badge badge-success mx-2">{{ x+1 }}</span>
        </card>
      </div>
    </draggable>


    <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
              <button class="btn btn-info" @click="confirmModal()">Confirm</button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'
import { SelectPage } from 'v-selectpage'
import {Select, Option} from 'element-ui';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import draggable from 'vuedraggable';
import PaginatedSelect from "../../../../components/paginatedSelect";


export default {
  name: 'third-section-modal',
  components: {
    PaginatedSelect,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    'v-selectpage': SelectPage,
    vSelect,
    draggable,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    selectedNews:{},
  },
  data() {

    return {
      draggableNews: this.selectedNews.news,
      url: this.selectedNews.url,
      title:this.selectedNews.title,
      sub_title:this.selectedNews.sub_title,
      newsList:[],
      newsCount:0,
      selectKey: 0,
      paginatedSelectKey: 0,

    }
  },
mounted() {

  this.draggableNews = this.selectedNews.news;
  this.title = this.selectedNews.title;
  this.sub_title = this.selectedNews.sub_title;
  this.url = this.selectedNews.url;
},
  watch: {
      selectedNews: {
          handler: function (newValue) {
              if (newValue) {
                this.draggableNews = this.selectedNews.news;
                this.title = this.selectedNews.title;
                this.sub_title = this.selectedNews.sub_title;
                this.url = this.selectedNews.url;
                this.paginatedSelectKey++;
              }
          },
          deep: true
      }
  },
  methods: {
    draggableNewsChanged(selectedNews){
      this.draggableNews = selectedNews
    },
    closeModal() {
      this.$emit('close');
    },
    confirmModal() {
      let data = {
          title: this.title,
          sub_title: this.sub_title,
          url: this.url,
          news:  this.draggableNews
      }
      this.$emit('selectedNews', data);
      this.closeModal();
    },
    newsPaginted(){
      let helperData = {
        offset: this.offset,
        limit: this.limit,
        search: this.search
      };
      this.axios.post("ar/news/paginatedNews", helperData).then((response) => {
        this.newsList = response.data.news;
        this.newsCount = response.data.newsCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    moveEventfunction () {
      // this.news.map((element, index) => { element.sort_order =  index})
    },
      generateURL() {

          let newUrl = '';
          if (this.title) {
              newUrl = this.title.replace(/\s+/g, "-").toLowerCase();
              newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
              newUrl = newUrl.replace(/([-]+)/g, "-");
          }

          this.url = newUrl;
      }
  }

}
</script>
<style>

.style-chooser .vs__selected {
  border-radius: 3px !important;
  border-color: #23CCEF;
  background-color: white;
  border-radius: 2px;
  color: #23CCEF;
}
.style-chooser .vs__deselect{
  background-color: transparent;
  color: #23CCEF;
  opacity: 1;
  font-size: 12px;
}
.style-chooser .vs__dropdown-option--selected{
  color: #23CCEF;
}
.style-chooser .prev-next{
  margin-top: 15px;
}
.style-chooser .prev-next button{
  margin-right: 15px;
  margin-left: 10px;
}
</style>
