<template>
  <div>
    <label class="card-label">{{ labelTitle }}</label>
    <v-select
      :multiple="isMultiple"
      label="title"
      class="style-chooser"
      @option:deselected="selectChanged"
      @option:selected="selectChanged"
      :key="selectKey"
      v-model="draggableSelected"
      :options="paginated"
      :filterable="false"
      v-on:input="limiter"
      @search="onSearch">
      <li slot="list-footer" class="pagination prev-next">
        <button class="btn btn-info" :disabled="!hasPrevPage" @click="prevPage">Prev</button>
        <button class="btn btn-info" :disabled="!hasNextPage" @click="nextPage">Next</button>
      </li>
    </v-select>
  </div>
</template>

<script>
import {Dialog} from 'element-ui'
import vSelect from "vue-select";

export default {
  name: 'paginated-select',

  components: {
    [Dialog.name]: Dialog,
    vSelect,
  },

  props: {
    labelTitle: {
      type: String,
      default: 'Choose from select'
    },
    isMultiple: {
      type: Boolean,
      default: true
    },
    filterData: {
      type: Object,
      default(){
        return null
      }
    },
    apiUrl: {
      type: String,
      default: 'ar/news/paginatedNews'
    },
    limit: {
      type: Number,
      default: 5
    },
    lastData: {
      type: Array,
      default(){
        return []
      }
    },
    selectLimiter:{
      type: Number,
      default: null,
    },
    columnsName:{
      type: Array,
      default() {
        return ['id', 'title']
      }
    },
    lang: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      draggableSelected: [],
      selectList: [],
      selectKey: 0,
      search: '',
      offset: 0,
    }
  },
  mounted() {
    this.resetPaginator();
    this.draggableSelected = this.lastData;
    this.dataPaginated();
  },
  watch: {
    lastData: {
      handler: function (newValue) {
        this.draggableSelected = this.lastData;
      },
      deep: true
    },
  },
  computed: {
    paginated() {
      return this.selectList;
    },
    hasNextPage() {
      return this.selectList.length > this.limit -1;
    },
    hasPrevPage() {
      return this.offset !== 0;
    },
  },
  methods: {
    selectChanged(){
      this.selectKey++;
      this.$emit('select-changed', this.draggableSelected);
    },
    confirmModal() {
      this.$emit('confirm');
    },
    dataPaginated() {

      let filterData = this.filterData;
      let paginatorData = {
        offset: this.offset,
        limit: this.limit,
        search: this.search,
        columns: this.columnsName,
        lang: this.lang != '' ? this.lang : null,
        filters: this.filterData ? [this.filterData] : []
      };
      this.axios.post(this.apiUrl, paginatorData).then((response) => {
        // this.selectList = response.data.news;
          this.selectList = response.data.dataList;
        this.dataCount = response.data.dataCount;
      }).catch((error) => {
        console.error(error);
      })
    },
    resetPaginator(){
      this.offset = 0;
      // this.limit = 10;
    },
    nextPage() {
      this.offset += this.limit;
      this.dataPaginated();
    },
      prevPage() {
        this.offset -= this.limit;
        this.dataPaginated();
      },
      onSearch(query) {
        this.search = query
        this.offset = 0
        this.dataPaginated();
      },
      limiter(e) {
        if (this.selectLimiter){
          if (e.length > this.selectLimiter) {
            e.pop()
          }
        }
      },


  }

}
</script>

<style>
    .line {
        position: absolute;
        left: 49%;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #ccc;
        z-index: 1;
    }

    .wordwrapper {
        text-align: center;
        height: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -12px;
        z-index: 2;
    }

    .word {
        color: #ccc;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 3px;
        font: bold 12px arial,sans-serif;
        background: #fff;
    }
    .google_secret{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .el-dialog--center .el-dialog__footer {
        text-align: right;
    }
</style>
